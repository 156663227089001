import React, { Component } from 'react';
import {  Modal, ModalHeader, ModalFooter, Button} from 'reactstrap'
import Parser from 'html-react-parser'

import * as Constants from '../../../helpers/constants'
import appEventEmitter, { AppEvents } from '../../../helpers/AppEventEmitter'
import {ModalControllerContext} from '../../../controller/modal_controller'


class VersionUpdateModalComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //showModal: false,
      reloadType: '',
      updateMessage: ''
    }
  }

  componentDidMount() {

    if (window) {
      if (!window.clearout) {
        window.clearout = []
      }
      window.clearout["versionupdate"] = this
    } else {
      console.log("Error: window object not found, app not running in brwoser environment")
    }
  }

  closeModal = () => {
    let modelController = this.context;
    modelController.dispatchModal({name:Constants.VERSION_MODAL,isOpen:false})
    //this.setState({ showModal: false });
  }

  openModal = (res) => {
    let modelController = this.context;
    modelController.dispatchModal({name:Constants.VERSION_MODAL,isOpen:true})
    this.setState({
      // showModal: true,
      reloadType: res.updateCode,
      updateMessage: res.updateMessage
    })
  }

  onUpdateButtonClick = () => {
    // app emitter event
    appEventEmitter.emit(AppEvents.app_update_button_clicked, {app_version : APP_VERSION})
    this.reload()
  }

  reload = () => {
    //this.setState({ showModal: false })
    if (this.state.reloadType === Constants.HARD_RELOAD) {
      window.location.reload(true)
    } else if (this.state.reloadType === Constants.EMPTY_DATA_AND_HARD_RELOAD) {
      window.localStorage.clear();
      window.location.reload(true);
    } else {
      window.location.reload()
    }
  }
  render() {
    const logo = IMAGES_LOGO_BLACK
    let modelController = this.context;
    let isModalOpen = modelController.modalState.name==Constants.VERSION_MODAL && modelController.modalState.isOpen ? true : false
    return (
      <Modal 
        isOpen={isModalOpen} 
        toggle={this.closeModal} 
        className="versioncheck" 
        size={'small'}
      >
        <ModalHeader toggle={this.closeModal} style={{ textAlign: 'center' }}>
          <img className="logo-default mb-2" alt='Clearout' src={logo}/>
          <div style={{ display: 'inline-block'}}>
            {Parser(this.state.updateMessage)}
          </div>
        </ModalHeader>
        <ModalFooter style={{backgroundColor: 'transparent'}}>
          <Button className="btn-clearout m-auto" onClick={this.onUpdateButtonClick}>Update now</Button>
        </ModalFooter>
      </Modal>
    )
  }

}

VersionUpdateModalComp.contextType = ModalControllerContext

export function versionupdate() {
  return window.clearout["versionupdate"]
}

export default VersionUpdateModalComp