import React from 'react'
import appEventEmitter, { AppEvents } from '../../helpers/AppEventEmitter'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ModalControllerContext } from '../../controller/modal_controller'
import * as Constants from "../../helpers/constants"
import _ from 'lodash'
import global from '../../helpers/global'

const MODELALERT = "modalalert"
const INIT_STATE = {
  show: false,
  title: "Untitled",
  content: "Message has to go here",
  onCloseHandler: null,
  onHideHandler: null,
  defaultButtonText: 'Ok'
}

class AlertModalComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = INIT_STATE
    this.isopened = false
  }

  componentDidMount() {
    global.set(MODELALERT, this)
  }

  show({ title, content, onCloseHandler, onHideHandler, defaultButtonText }) {
    let buttonText = defaultButtonText ? defaultButtonText : this.state.defaultButtonText

    if (!this.isopened) {
      let modelController = this.context;
      this.isopened = true
      modelController.dispatchModal({
        name: Constants.ALERT_MODAL,
        isOpen: true,
        params: {
          title,
          content,
          onCloseHandler,
          onHideHandler,
          defaultButtonText: buttonText
        }
      })

    }
  }

  hide = () => {
    let modelController = this.context;
    modelController.dispatchModal({ name: Constants.ALERT_MODAL, isOpen: false })
    this.isopened = false

    modelController.modalState.name == Constants.ALERT_MODAL && !_.isNil(modelController.modalState.params.onHideHandler) && modelController.modalState.params.onHideHandler()

    this.setState(INIT_STATE)
  }

  close = () => {
    let modelController = this.context;
    modelController.dispatchModal({ name: Constants.ALERT_MODAL, isOpen: false })
    appEventEmitter.emit(AppEvents.user_clicked_close_button_on_unauthorized_modal, { app_version: APP_VERSION })
    this.isopened = false
    modelController.modalState.name == Constants.ALERT_MODAL && !_.isNil(modelController.modalState.params.onCloseHandler) && modelController.modalState.params.onCloseHandler()
    this.setState(INIT_STATE)
  }

  isOpened() {
    return this.isopened
  }

  render() {
    let modelController = this.context;
    let modalOpen = modelController.modalState.name == Constants.ALERT_MODAL && modelController.modalState.isOpen ? true : false
    let title = modelController.modalState.name == Constants.ALERT_MODAL && !_.isNil(modelController.modalState.params.title) ? modelController.modalState.params.title : ''
    let content = modelController.modalState.name == Constants.ALERT_MODAL && !_.isNil(modelController.modalState.params.content) ? modelController.modalState.params.content : ''
    let buttonText = modelController.modalState.name == Constants.ALERT_MODAL && !_.isNil(modelController.modalState.params.defaultButtonText) ? modelController.modalState.params.defaultButtonText : this.state.defaultButtonText

    return (
      <Modal
        isOpen={modalOpen}
        className='alert-modal'
      >
        <ModalHeader
          toggle={this.hide}
        >
          {title}
        </ModalHeader>
        <ModalBody>
          {content}
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-clearout" onClick={this.close}>
            {buttonText}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

AlertModalComp.contextType = ModalControllerContext

export function ModalAlert() {
  return global.get(MODELALERT)
}

export default AlertModalComp