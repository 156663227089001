import appEventEmitter, { AppEvents } from './AppEventEmitter'

const MatomoTracker = {

  _registerAllEvents: function () {
    // Login Success
    appEventEmitter.addListener(AppEvents.user_login_success, (userInfo) => {
      this.setUserId(userInfo)
    })

    let appEventListeners = []
    for (let eventProp in AppEvents) {
      if (AppEvents.hasOwnProperty(eventProp)) {
        let eventVal = AppEvents[eventProp]
        let listener = appEventEmitter.addListener(eventVal, (props) => {
          // console.log(`matomo tracking event ${eventVal} ${JSON.stringify(props)}`)
          window._paq.push(['trackEvent', eventVal, JSON.stringify(props)])
        })
        appEventListeners.push(listener)
      }
    }

    appEventEmitter.addListener(AppEvents.username_update_success, (user) => {
      window._paq.push(['setCustomDimension', 2, user.name])
    })

    appEventEmitter.addListener(AppEvents.user_clicked_logout_btn, () => {
      console.info('Reseting matomo..')
      window._paq.push(['resetUserId']);
      window._paq.push(['trackPageView']);
    })
  },

  _unRegisterAllEvents: function () {
    // todo
  },

  getMatomoVisitorId: function () {
    let visitorId
    window._paq.push([function () {
      visitorId = this.getVisitorId()
    }
    ])
    return visitorId
  },

  setUserId: function (user) {
    if (user.email) {
      window._paq.push(['setUserId', user.id])
      window._paq.push(['trackPageView'])

      window._paq.push(['setCustomDimension', 1, user.email])
      window._paq.push(['setCustomDimension', 2, user.name])
      window._paq.push(['setCustomDimension', 3, user.user_id])
    }
  },

  init: function () {
    if (MATOMO) {
      this._registerAllEvents()
    }
  }
}

export default MatomoTracker
