
import React, { Component } from 'react'

import NotificationSystem from 'react-notification-system'

class Notifications extends Component {

  constructor(props) {
    super(props)

    this.notificationSystem = null
  }

  componentDidMount() {

    if (window) {
      if (!window.clearout) {
        window.clearout = []
      }
      window.clearout["notification"] = this
    } else {
      console.log("Error: window object not found, app not running in brwoser environment")
    }
  }

  removeNotification = (uid) => {
    this.notificationSystem.removeNotification(uid)
  }

  // 'both' | 'button' | 'click' | 'hide' | 'none' | boolean | undefined
  showNotification = (notificationDetails) => {
    this.notificationSystem.addNotification({
      title: notificationDetails.title,
      message: notificationDetails.message,
      level: notificationDetails.level,
      position: 'tc',
      uid: notificationDetails.uid ? notificationDetails.uid : '',
      autoDismiss: (notificationDetails.autoDismiss || notificationDetails.autoDismiss === 0) ? notificationDetails.autoDismiss : 5,
      dismissible: notificationDetails.dismissible ? notificationDetails.dismissible : true,
      // action: {
      //     label: notificationDetails.actionLabel,
      //     callback: function () {
      //         if (notificationDetails.actionCallback) {
      //             if (notificationDetails.callbackParameters)
      //                 notificationDetails.actionCallback(notificationDetails.callbackParameters)
      //             else
      //                 notificationDetails.actionCallback()
      //         }
      //     }
      // }
    });
  }

  render() {
    var style = {
      Containers: { // Override the notification item
        tc: { // Applied to every notification, regardless of the notification level
          top: '40px'
        }
      }
    }

    return (
      <div style={{ zIndex: '10500' }}>
        <NotificationSystem ref={(input) => { this.notificationSystem = input }} style={style} />
      </div>
    )
  }
}

export function Notify() {
  return window.clearout["notification"]
}

export default Notifications