import React from 'react'
import { withRouter } from 'react-router-dom'
import global from '../../helpers/global'


class RedirectComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
    global.set('redirect', this)
  }

  redirect(path) {
    this.props.history.push(path)
  }

  render () {
    return null
  }
}

export function Redirect() {
  return global.get('redirect')
}

export default withRouter((RedirectComp))