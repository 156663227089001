const SLABS = 'slabs'

const initialState = {
  slabs : null, 
  minCredit : 0
}

export default function reducer(state = {...initialState}, action) {
  switch(action.type) {
    case SLABS : {
      return Object.assign({}, state, {
        slabs : action.payload.slabs,
        minCredit : action.payload.minCredit
      })
    }
    default:
      return state
  }
}
//set the reducer state
export function setPricingSlabs(payload){
  return{
    type : SLABS,
    payload
  }
}