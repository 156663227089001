let Global = (function(global) {

  if (global) {
    if (!global.clearout) {
      global.clearout = []
    }
  } 
  
  return {
    set: (key, obj) => {
      global.clearout[key] = obj
    },
  
    get: key => global.clearout[key]
  }
})(window)
  
export default Global