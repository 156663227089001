
const UPDATE_USER_SETTINGS = 'update_user_settings'
const UPDATE_NOTIFICATION_SETTINGS = 'update-notification-settings'
const UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS = 'update-low-credit-notification-settings'
const UPDATE_EF_SETTINGS = 'update-email-finder-settings'
const UPDATE_PROSPECT_SETTINGS = 'update-prospect-settings'
const UPDATE_EV_SETTINGS = 'update-email-verifier-settings'
const RESET_USER_SETTINGS = 'reset-user_settings'

const initialState = {
  notifications: {},
  low_credit_notification: {},
  ef_settings: {
    setting_last_modified_on: null,
    include_role_email: true,
    strict_domain_check: false
  },
  prospect_settings: {
    person_enrich_settings: {},
    company_enrich_settings: {},
    setting_last_modified_on: null,
  },
  ev_settings: {
    setting_last_modified_on: null,
    default_email_verify_mode: null,
    can_show_email_verify_mode: false,
    auto_pilot_status: false,
    gibberish_threshold_value : 'high'
  }
}

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case UPDATE_USER_SETTINGS: {
      const { ev_settings, ef_settings, prospect_settings, notifications, low_credit_notification } = action.payload;

      return Object.assign({}, state, {
        ev_settings: Object.assign({}, state.ev_settings, ev_settings),
        ef_settings: Object.assign({}, state.ef_settings, ef_settings),
        prospect_settings: Object.assign({}, state.prospect_settings, prospect_settings),
        notifications: Object.assign({}, state.notifications, notifications),
        low_credit_notification: Object.assign({}, state.low_credit_notification, low_credit_notification)
      });
    }

    case UPDATE_NOTIFICATION_SETTINGS:
      return Object.assign({}, state, {
        notifications: action.payload
      })

    case UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS:
      return Object.assign({}, state, {
        low_credit_notification: action.payload
      })

    case UPDATE_EF_SETTINGS: {
      const { key, value } = action.payload;

      return Object.assign({}, state, {
        ef_settings: {
          ...state.ef_settings,
          [key]: value,
        },
      });
    }

    case UPDATE_EV_SETTINGS: {
      const { key, value } = action.payload;

      return Object.assign({}, state, {
        ev_settings: {
          ...state.ev_settings,
          [key]: value,
        },
      });
    }

    case UPDATE_PROSPECT_SETTINGS: {
      const { key, value } = action.payload;

      return Object.assign({}, state, {
        prospect_settings: {
          ...state.prospect_settings,
          [key]: value,
        },
      });
    }

    case RESET_USER_SETTINGS: {
      return Object.assign({}, initialState);
    }

    default:
      return state
  }
}

//set the reducer state
export function updateUserSettings(payload) {
  return {
    type: UPDATE_USER_SETTINGS,
    payload
  }
}

export function updateNotificationSettings(payload) {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS,
    payload
  }
}

export function updateLowCreditNotificationSettings(payload) {
  return {
    type: UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS,
    payload
  }
}

//update only email finder settings 

export function updateEfSettings(payload) {
  return {
    type: UPDATE_EF_SETTINGS,
    payload,
  };
}

//update only emaail verifier settings 

export function updateEvSettings(payload) {
  return {
    type: UPDATE_EV_SETTINGS,
    payload,
  };
}

//update only prospect settings 

export function updateProspectSettings(payload) {
  return {
    type: UPDATE_PROSPECT_SETTINGS,
    payload,
  };
}

export function resetUserSettings(payload) {
  return {
    type: RESET_USER_SETTINGS,
    payload,
  };
}