import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import { userReducer } from './components/user'
import appReducer from './appReducer'
import userSettingsReducer from './components/user_settings/userSettingsReducer'

export default combineReducers({
  routing: routerReducer,
  appReducer,
  userReducer,
  userSettingsReducer
})